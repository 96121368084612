<template lang="pug">
  .altura
    .modale(v-if="isModalOpen")
      img.margin-top(src="../assets/sancome-logo.png" width="20%")
      a.link-modal(v-on:click="changePage(0)" href="#") Inicio
      a.link-modal(v-on:click="changePage(1)" href="#") Premisa
      a.link-modal(v-on:click="changePage(2)" href="#") Contexto
      a.link-modal(v-on:click="changePage(3)" href="#") Arquitectos
      a.link-modal(v-on:click="changePage(4)" href="#") Diferenciadores
      a.link-modal(v-on:click="changePage(5)" href="#") Áreas comunes
      a.link-modal(v-on:click="changePage(6)" href="#") Estudios
      a.link-modal(v-on:click="changePage(7)" href="#") Galería
      a.link-modal(@click="toggleModal") Cerrar

    .background(v-if="pagina==0")
      img(src="../assets/sancome-logo.png" width="40%")
      br
      br
      br
      a.btn-sancosmi(href="#" @click="toggleModal"): b Comenzar
    
      div.main
      footer.footer
        .row
          .col-md-12
            p HAN Capital Here & Now es una marca registrada

    .premisa(v-else-if="pagina==1").premisaback
      .row.premisaback
        .col-md-8.col-md-offset-2
          .col-md-1.col-md-offset-12
            a.btn-sancosme(href="#" @click="toggleModal"): img(src="../assets/menu-abierto.png")
          h1 PREMISA
          p El objetivo del proyecto radica en aportar a la ciudad de México y a la Colonia San Rafael, una alternativa de vivienda práctica y accesible. Alternativa que ofrecerá soluciones innovadoras en el ámbito arquitectónico, de construcción y de diseño. Estás soluciones resolverán necesidades que hoy no han sido cubiertas por ningún otro proyecto en la zona.
          p Nuestro compromiso al crear este proyecto icónico, es el de ejecutarlo de manera armónica; respetando el entorno ya existente y promoviendo a su vez un sentido de comunidad, de “barrio”, de pertenencia.
          p La oferta comercial se integrará también con la “personalidad” de San Cosme, buscando soluciones comerciales que aporten a la identidad de la Colonia.
          p Por todo lo anterior, San Cosme se convertirá, en el referente integral del resurgimiento de la Colonia San Rafael.
          img(src="../assets/proyecto.png" width="100%")
      br
      br
      br
      br
      div.main
      footer.footer
        .row
          .col-md-12
            p HAN Capital Here & Now es una marca registrada

    .contexto(v-else-if="pagina==2").contextoback
      .row.contextoback
        .col-md-8.col-md-offset-2
          .col-md-1.col-md-offset-12
            a.btn-sancosme(href="#" @click="toggleModal"): img(src="../assets/menu-abierto.png")
          h1 CONTEXTO
          
          VueSlickCarousel(v-bind="settings")
            div 
              .pad.padback
                .slide
                  br
                  img(src="../assets/historia.png" width="80%")
                  .text
                    p.title Contexto histórico
                    p En una de las primeras colonias en CDMX y al lado del icónico Mercado de San cosme.
                    br                    
            div 
              .pad.padback
                .slide
                  br
                  img(src="../assets/barrio.png" width="80%")
                  .text
                    p.title Colonia emergente
                    p En proceso de revitalización urbana con centros culturales y atractivos históricos icónicos.
                    br
            div 
              .pad.padback
                .slide
                  br
                  img(src="../assets/camino.png" width="35%")
                  .text
                    p.title Gran conectividad
                    p Cerca de varias de las líneas de transporte público y ejes viales mas importantes.
                    br
            div 
              .pad.padback
                .slide
                  br
                  img(src="../assets/mercado.png" width="80%")
                  .text
                    p.title Mercado San Cosme
                    p Con gran oferta comercial y gastronómica; en remodelación actualmente.
                  
            div 
              .pad.padback
                .slide
                  br
                  img(src="../assets/jardin.png" width="80%")
                  .text
                    p.title Espacios publicos
                    p A solo unas cuadras de las plazas y jardines mas importantes de la colonia.
            div 
              .pad.padback
                .slide
                  br
                  img(src="../assets/teatro.png" width="80%")
                  .text
                    p.title Teatros
                    p Más de 7 teatros con gran oferta cultural.
                    br
            div 
              .pad.padback
                .slide
                  br
                  img(src="../assets/colegio.png" width="80%")
                  .text
                    p.title Escuelas
                    p Oferta académica amplia en todos los niveles.
                    br
            div 
              .pad.padback
                .slide
                  br
                  img(src="../assets/museo (1).png" width="80%")
                  .text
                    p.title Galerías de arte
                    p Privadas y museos como el Chopo, San Carlos, Museo de la Revolución entre otros.
            div 
              .pad.padback
                .slide
                  br
                  img(src="../assets/urbano.png" width="80%")
                  .text
                    p.title Oficinas
                    p Al lado del corredor más importante del la ciudad de México Reforma - Centro.
      
        div
          .col-md-10.col-md-offset-2
            img(src="../assets/img07.jpg" width="80%")
          
        .row
          .col-md-10.col-md-offset-2
            .col-md-5
              img(src="../assets/img04.jpg" width="80%")
            .col-md-5
              img(src="../assets/img02.jpg" width="80%")
          
          .col-md-10.col-md-offset-2
            .col-md-3
              img(src="../assets/img01.jpg" width="80%")
            .col-md-3
              img(src="../assets/img03.jpg" width="80%")
            .col-md-3
              img(src="../assets/img05.jpg" width="80%")
          
          .col-md-10.col-md-offset-2
            .col-md-5
              img(src="../assets/img06.jpg" width="80%")
            .col-md-5
              img(src="../assets/img08.jpg" width="80%")
 
        br
        br
        br        
            
      footer.footer
        .row
          .col-md-12
            p HAN Capital Here & Now es una marca registrada

    .arquitectos(v-else-if="pagina==3")
      .row.arquitectosback
        .col-md-8.col-md-offset-2
          .col-md-1.col-md-offset-12
            a.btn-sancosme(href="#" @click="toggleModal"): img(src="../assets/menu-abierto.png")
          h1 MYT + GLVDK
          h2 ARQUITECTOS
          p Firma mexicana multidisciplinar con visión global, surgida de la colaboración de Andrés Mier y Terán y Regina Galvanduque, ofrece soluciones innovadoras, funcionales y atractivas con una estética depurada en torno a conceptos sólidos y bien definidos que se traducen en productos y experiencias memorables. Mediante una metodología de trabajo 360o que integra tres pilares esenciales, la arquitectura, el diseño industrial y el branding, el estudio MYT+GLVDK concibe para cada cliente una narrativa particular que permita contar su propia historia.

          p Con notable experiencia en el ámbito de la hospitalidad, retail, residencial, instituciones culturales y exposiciones, los proyectos de MYT+GLVDK se caracterizan por la convergencia de tradición y vanguardia aunando calidad, practicidad y sofisticación.
          VueSlickCarousel(v-bind="settings")
            div 
              .pad.padback
                .slide.otrocarro
                  img(src="../assets/Moshi.png" width="100%")
                  .text
                    p.title MOSHI
                    p Diseño y ejecución de interiores y mobiliario para restaurante japonés con banda.
                    br
            div 
              .pad.padback
                .slide.otrocarro
                  img(src="../assets/cocinaAbierto.png" width="100%")
                  .text
                    p.title COCINA ABIERTA
                    p Diseño y desarrollo de interiores y mobiliario para restaurante con 8 conceptos gastrónomicos.
                    br
            div 
              .pad.padback
                .slide.otrocarro
                  img(src="../assets/Ryo kan.png" width="100%")
                  .text
                    p.title RYO KAN
                    p Renovación de propiedad para convertirla en el primer hotel tipo Ryokan de latinoámerica.
            div 
              .pad.padback
                .slide.otrocarro
                  img(src="../assets/Baldio.png" width="100%")
                  .text
                    p.title TERRENO BALDÍO
                    p Rehabiltación de edificio hístorico para centro cultural.
            div 
              .pad.padback
                .slide.otrocarro
                  img(src="../assets/niddo.png" width="100%")
                  .text
                    p.title NIDDO
                    p Conceptualización y ejecución de una cafetería y restaurante en la colonia Juárez de la CDMX
            div 
              .pad.padback
                .slide.otrocarro
                  img(src="../assets/coleccionPrima.png" width="100%")
                  .text
                    p.title COLECCIÓN PRIMA
                    p Diseño de colección de mobiliario en colaboración con la marca mexicana Pirwl.
          br
          br
          br
      footer.footer
        .row
          .col-md-12
            p HAN Capital Here & Now es una marca registrada

    .diferenciadores(v-else-if="pagina==4")
      .row.diferenciadoresback
        .col-md-8.col-md-offset-2
          .col-md-1.col-md-offset-12
            a.btn-sancosme(href="#" @click="toggleModal"): img(src="../assets/menu-abierto.png")
          br
          br
          h1 Diferenciadores
          VueSlickCarousel(v-bind="settingsOne")
            div 
              .pad.padback
                .slide.otrocarro
                  .row
                    .col-md-6
                      img(src="../assets/image2.jpg" width="100%")
                    .col-md-5
                      h3.title FACHADA HISTÓRIA
                      p Original de principios del s.XX con balcones y detalles en cantera únicos de esa época.
                      br
                      h3.title RECUPERACIÓN HISTÓRICA
                      p Departamentos en edificio histórico con techos de bóveda catalana de tabique, así como también detalles y materiales restaurados del principio de siglo.
            div 
              .pad.padback
                .slide.otrocarro
                  .row
                    .col-md-6
                      img(src="../assets/image1.jpg" width="100%")
                    .col-md-5
                      p.title VIVIENDA EFICIENTE
                      p 32 tipologías diferentes que aprovechan al máximo el espacio con una diversa y astuta oferta de soluciones.
                      br
                      p.title MERCADO SAN COSME
                      p A espaldas del icónico edificio que alberga una gran oferta gastronómica y comercial.
            div 
              .pad.padback
                .slide.otrocarro
                  .row
                    .col-md-6
                      img(src="../assets/image11.jpg" width="100%")
                    .col-md-5
                      p.title ESTUDIOS
                      p Departamentos monoambiente diseñados hasta el último detalle
                      br
                      p.title ACCESOS INDEPENDIENTES
                      p Todo los comercios en PB tiene un acceso frontal logrando una independencia y privacidad total para todos los departamentos.
            div 
              .pad.padback
                .slide.otrocarro
                  .row
                    .col-md-6
                      img(src="../assets/image7.jpg" width="100%")
                    .col-md-5
                      p.title PATIOS INTERNOS
                      p Iluminación y ventilación natural de todas las unidades con vista a jardines interiores.
                      br
                      p.title ALTURA 3.5M
                      p Mínima o mayor de piso a techo en todos los departamentos del edificio histórico y una doble altura en los del 4 nivel.
            div 
              .pad.padback
                .slide.otrocarro
                  .row
                    .col-md-6
                      img(src="../assets/image10.jpg" width="100%")
                    .col-md-5
                      p.title ESPACIOS COMUNES
                      p Centro de negocios, salón de eventos con patios comunes, portería, gimnasio, wellness y canal de nado de 25m.
                      br
                      p.title MOBILIARIO PERSONALIZADO
                      p Se ofrecerán opcionalmente, paquetes de mobiliario diseñado especialmente para aprovechar al máximo cada espacio, así como ofrecer departamentos llave en mano para vivirlos o rentarlos.
            div 
              .pad.padback
                .slide.otrocarro
                  .row
                    .col-md-6
                      img(src="../assets/image8.jpg" width="100%")
                    .col-md-5
                      p.title COMERCIO EN PB
                      p 850m2 sobre 95 metros lineales de frente a calle con una oferta amplia de servicios de valor agregado para todos los habitantes de la zona y el edificio.
                      br
                      p.title ROOFDECK
                      p Con canal de nado semi- olímpico (25m) y áreas de asadores, fogatas y recreación.

            div 
              .pad.padback
                .slide.otrocarro
                  .ro
                    .col-md-6
                      img(src="../assets/image1.jpg" width="100%")
                    .col-md-5
                      p.title VIVIENDA EFICIENTE
                      p 32 tipologías diferentes que aprovechan al máximo el espacio con una diversa y astuta oferta de soluciones.
                      br
                      p.title MERCADO SAN COSME
                      p A espaldas del icónico edificio que alberga una gran oferta gastronómica y comercial.
            
        
      img(src="../assets/scarq01.png" width="100%")
      img(src="../assets/planta2.png" width="100%")
      img(src="../assets/planta3.png" width="100%")
      img(src="../assets/scarq04.png" width="100%")
      img(src="../assets/scarq5.png" width="100%")

      footer.footer
        .row
          .col-md-12
            p HAN Capital Here & Now es una marca registrada

    .areascomunes(v-else-if="pagina==5")
      .row.areascomunesback
        .col-md-8.col-md-offset-2
          .col-md-1.col-md-offset-12
            a.btn-sancosme(href="#" @click="toggleModal"): img(src="../assets/menu-abierto.png")
          br
          br
          h1 ÁREAS COMUNES
          VueSlickCarousel(v-bind="settingsOne")
            div 
              .pad.padback
                .slide.otrocarro
                  .row
                    .col-md-6
                      img(src="../assets/image8.jpg" width="100%")
                    .col-md-5
                      h3.title CANALES DE NADO Y WELLNESS
                      p Canal semi-olímpico de 25m, jacuzzi de 10 personas, sauna, vapor y amplio gimnasio equipado.
                      br
                      h3.title SALÓN INGLÉS
                      p Sala de proyección, bar, billar y salas con fogatas para uso exclusivo de los condóminos.
            div 
              .pad.padback
                .slide.otrocarro
                  .row
                    .col-md-6
                      img(src="../assets/image5.jpg" width="100%")
                    .col-md-5
                      p.title ÁREA DE NIÑOS
                      p Segura y didáctica en áreas abiertas confinadas dentro de los patios del edificio.
                      br
                      p.title CENTRO DE NEGOCIOS
                      p Oficinas individuales, sala de juntas y centro de impresión y amplias salas de recepción en patio de acceso.
            div 
              .pad.padback
                .slide.otrocarro
                  .row
                    .col-md-6
                      img(src="../assets/image9.jpg" width="100%")
                    .col-md-5
                      p.title ROOFDECK
                      p Con inmejorables vistas a la ciudad, salas y comedores con asador y fogatas.
                      br
                      p.title PASILLOS ABIERTOS
                      p Para iluminación y ventilación natural a lo largo de todos el proyecto.
            div 
              .pad.padback
                .slide.otrocarro
                  .row
                    .col-md-6
                      img(src="../assets/image7.jpg" width="100%")
                    .col-md-5
                      p.title DOS CUERPOS DE ESCALERAS
                      p Para mejorar circulación y garantizar agilidad en evacuación de emergencia.
                      br
                      p.title ELEVADORES
                      p En 2 de los patios internos para garantizar el mínimo tiempo de espera.
            div 
              .pad.padback
                .slide.otrocarro
                  .row
                    .col-md-6
                      img(src="../assets/image6.jpg" width="100%")
                    .col-md-5
                      p.title SERVICIOS
                      p Adicionales a las amenidades privadas a pie de calle algunos de los 13 locales comerciales se destinarán a usos de valor agregado a los departamentos.
                      br
                      p.title CONSERJERÍA
                      p Paquetería, mantenimiento, basura, bicis, portería y seguridad 24/7
      footer.footer
        .row
          .col-md-12
            p HAN Capital Here & Now es una marca registrada

    .estudios(v-else-if="pagina==6").estudiosback
      .row.estudiosback
        .col-md-8.col-md-offset-2
          .col-md-1.col-md-offset-12
            a.btn-sancosme(href="#" @click="toggleModal"): img(src="../assets/menu-abierto.png")
          br
          br
          h1 PERKS ESTUDIOS
          VueSlickCarousel(v-bind="settingsOne")
            div 
              .pad.padback
                .slide.otrocarro
                  .row
                    .col-md-6
                      img(src="../assets/image11.jpg" width="100%")
                    .col-md-5
                      h3.title UNIDADES 112
                      p Unidades de vivienda.
                      br
                      h3.title RANGO 22-54
                      p Rango de m2 de viviendas.
            div 
              .pad.padback
                .slide.otrocarro
                  .row
                    .col-md-6
                      img(src="../assets/image13.jpg" width="100%")
                    .col-md-5
                      p.title ALTURA 3.5M
                      p Altura en todos los departamentos de 3.5m o mayores según el nivel del edificio histórico
            div 
              .pad.padback
                .slide.otrocarro
                  .row
                    .col-md-6
                      img(src="../assets/image8.jpg" width="100%")
                    .col-md-5
                      p.title AMENIDADES
                      p Canal de nado, wellness, fitness y centro de negocios.
                      br
                      p.title LLAVE EN MANO
                      p Opción de compra de paquete de mobiliarios diseñado para maximizar el potencial de cada unidad.
            div 
              .pad.padback
                .slide.otrocarro
                  .row
                    .col-md-6
                      img(src="../assets/image6.jpg" width="100%")
                    .col-md-5
                      p.title MOBILIARIO
                      p Producido por la reconocida mueblería PIRWI, diseñado por MYT+GLVK, personalizado para el proyecto y por un precio único
                      br
                      p.title ACABADOS
                      p Inspirados en los materiales usados en construcciones de la época en contraste con una visión contemporánea de su uso.
            div 
              .pad.padback
                .slide.otrocarro
                  .row
                    .col-md-6
                      img(src="../assets/image4.jpg" width="100%")
                    .col-md-5
                      p.title ACCESO
                      p Acceso controlado con portería y seguridad 24/7.
                      br
                      p.title ESPACIOS ABIERTOS
                      p Terrazas, patios y balcones.
            div 
              .pad.padback
                .slide.otrocarro
                  .row
                    .col-md-6
                      img(src="../assets/image7.jpg" width="100%")
                    .col-md-5
                      p.title SOLUCIONES
                      p Distribución con cama confinadas o elevadas para mejor aprovechamiento del espacio.
                      br
                      p.title VENTILACIÓN
                      p Cruzada en todas las unidades

            div 
              .pad.padback
                .slide.otrocarro
                  .row
                    .col-md-6
                      img(src="../assets/image3.jpg" width="100%")
                    .col-md-5
                      p.title ORIENTACIÓN
                      p Idónea para mejor temperatura e iluminación
                      br
                      p.title UBICACIÓN
                      p En el centro de la CDMX.
            div 
              .pad.padback
                .slide.otrocarro
                  .row
                    .col-md-6
                      img(src="../assets/image2.jpg" width="100%")
                    .col-md-5
                      p.title RESTAURACIÓN
                      p Fachada, bóvedas catalanas, modulación de ventanas en fachada, molduras marcos de puertas y todo los elementos originales del edificio histórico
                      br
                      p.title VISTAS
                      p 19 Unidades interiores con vista a 6 patios y 16 unidades con vista a la calle por nivel.
      br
      br
      .col-md-10.col-md-offset-1
        h1 CLAVE SS - TIPO E
      div  
        .row
          .col-md-10.col-md-offset-1
            .col-md-3
              img(src="../assets/tipoE.png" width="100%")
            .col-md-4
              img(src="../assets/image11.jpg" width="100%")
            .col-md-4
              img(src="../assets/image12.jpg" width="100%")
      br
      br
      .col-md-10.col-md-offset-1
        h1 CLAVE S - TIPO F
      div  
        .row
          .col-md-10.col-md-offset-1
            .col-md-3
              img(src="../assets/tipof.png" width="100%")
            .col-md-4
              img(src="../assets/image13.jpg" width="100%")
      br
      br
      .col-md-10.col-md-offset-1
        h1 CLAVE R1 - TIPO M
      div  
        .row
          .col-md-10.col-md-offset-1
            .col-md-3
              img(src="../assets/tipoM.png" width="100%")
            .col-md-4
              img(src="../assets/image14.jpg" width="100%")
      br
      br
      .col-md-10.col-md-offset-1
        h1 CLAVE R1 - TIPO T
      div  
        .row
          .col-md-10.col-md-offset-1
            .col-md-3
              img(src="../assets/tipoT.png" width="100%")
            .col-md-4
              img(src="../assets/image15.jpg" width="100%")
      br
      br
      br
      
      footer.footer
        .row
          .col-md-12
            p HAN Capital Here & Now es una marca registrada  

    .galeria(v-else-if="pagina==7")
      .row.galeriaback
        .col-md-8.col-md-offset-2
          .col-md-1.col-md-offset-12
            a.btn-sancosme(href="#" @click="toggleModal"): img(src="../assets/menu-abierto.png")
          br
          br
          h1 GALERÍA
          div
            gallery( :images="images" :index="index" @close="index = null")
            div(class="image" v-for="(image, imageIndex) in images" :key="imageIndex" @click="index = imageIndex" :style="{ backgroundImage: 'url(' + image + ')', width: '300px', height: '200px' }" )
            br
            br
            br
        
    footer.footer
      .row
        .col-md-12
          p HAN Capital Here & Now es una marca registrada 
</template>


<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueGallery from 'vue-gallery'

export default {
    components: { VueSlickCarousel, 'gallery': VueGallery},
    data(){
        return {
          images: [
          'https://han.mx/galeria/image5.jpg',
          'https://han.mx/galeria/image6.jpg',
          'https://han.mx/galeria/image7.jpg',
          'https://han.mx/galeria/image8.jpg',
          'https://han.mx/galeria/image9.jpg',
          'https://han.mx/galeria/image10.jpg',
          'https://han.mx/galeria/image1.jpg',
          'https://han.mx/galeria/image2.jpg',
          'https://han.mx/galeria/image3.jpg',
          'https://han.mx/galeria/imagenew1.jpeg',
          'https://han.mx/galeria/imagenew2.jpg',
          'https://han.mx/galeria/imagenew3.jpeg',
          'https://han.mx/galeria/imagenew4.jpeg',
          'https://han.mx/galeria/imagenew5.jpeg',
          'https://han.mx/galeria/imagenew6.jpeg',
          'https://han.mx/galeria/imagenew7.jpeg',
          'https://han.mx/galeria/imagenew8.jpeg',
          'https://han.mx/galeria/imagenew9.jpeg',
          'https://han.mx/galeria/imagenewb1.jpeg',
          'https://han.mx/galeria/imagenewb2.jpeg',
          'https://han.mx/galeria/imagenewb3.jpeg',
          'https://han.mx/galeria/imagenewb4.jpeg',
          'https://han.mx/galeria/imagenewb5.jpeg',
          'https://han.mx/galeria/imagenewb6.jpeg',
          'https://han.mx/galeria/imagenewb7.jpeg',
          'https://han.mx/galeria/imagenewb8.jpeg',
          'https://han.mx/galeria/imagenewb9.jpeg',
          'https://han.mx/galeria/imagenewc1.jpeg',
        ],
        index: null,

          isModalOpen : false,
          pagina:0,
          settings:{
                "dots": true,
                "autoplay": true,
                "autoplaySpeed": 6000,
                "focusOnSelect": true,
                "infinite": true,
                "speed": 500,
                "slidesToShow": 3,
                "slidesToScroll": 3,
                "touchThreshold": 5
              },
          settingsOne:{
                "dots": true,
                "autoplay": true,
                "autoplaySpeed": 6000,
                "focusOnSelect": true,
                "infinite": true,
                "speed": 500,
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "touchThreshold": 5
              }
        }
    },
    methods:{
        toggleModal(){
            this.isModalOpen = !this.isModalOpen
        },
      changePage(page){
        this.pagina=page
        this.toggleModal()
      }
    },
}
</script>
<style scoped>
       .main {
  flex: 1;
}
  .footer {
  background-color: #ab9677;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 25px;
  position: fixed;
  left: 0;
  bottom: 0;
  color: white !important;
  font-size: 11px;
  color: rgb(255, 255, 255);
  margin-top: 10px;
  text-align: left;
}

@font-face {
        font-family: telegraf;
        src: url(../assets/telegraf.otf);
      }

.premisa{
  font-family: telegraf; 
  background-color: #DFDFD5;
  height:100%;
  width: 100%;
  height: 100%;
 
  z-index: 0;
}
.contexto{
  font-family: telegraf;
  background-color:#DFDFD5;
   /* background: url(../assets/backgroundSanCosme1.png);*/
  background-color: #DFDFD5;
  height:100%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  transition: all 3s;
}
.arquitecto{
  font-family: telegraf;
   padding: 10px;
  background-color:#DFDFD5;
   /* background: url(../assets/backgroundSanCosme1.png);*/
  background-color: #DFDFD5;
  height:100%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;

}
.diferenciador{
  font-family: telegraf;
   padding: 10px;
  background-color:#DFDFD5;
   /* background: url(../assets/backgroundSanCosme1.png);*/
  background-color: #DFDFD5;
  height:100%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
 
}
.areascomunes{
  font-family: telegraf;
   padding: 10px;
  background-color:#DFDFD5;
   /* background: url(../assets/backgroundSanCosme1.png);*/
  background-color: #DFDFD5;
  height:100%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;

}
.estudios{
  font-family: telegraf;

}
.galeria{
  font-family: telegraf;
   padding: 10px;
  background-color:#DFDFD5;
   /* background: url(../assets/backgroundSanCosme1.png);*/
  background-color: #DFDFD5;
  height:100%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;

}
.premisaback{
  font-family: telegraf;
}
.contextoback{
  font-family: telegraf;
  padding: 10px;
  background-color:#DFDFD5;
}
.arquitectosback{
  font-family: telegraf;
   padding: 10px;
  background-color:#DFDFD5;
}
.diferenciadoresback{
  font-family: telegraf;
   padding: 10px;
  background-color:#DFDFD5;
}
.areascomunesback{
  font-family: telegraf;
   padding: 10px;
  background-color:#DFDFD5;
}
.estudiosback{
  font-family: telegraf;
   padding: 10px;
  background-color:#DFDFD5;
}
.galeriaback{
  font-family: telegraf;
   padding: 10px;
  background-color:#DFDFD5;
}
.title{
  font-size:20px;
  font-weight: bold;
}
.text{
  text-align:center;
  padding-bottom:20px;
}
.pad{
  padding:5px;
}
.slide{
background-color:white;
}
.slide img{
 display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}
.slide.otrocarro img{
 display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.altura{
  overflow-x:hidden;
}
.link-modal{
  font-size:30px;
  margin-bottom:5px;
  color:white;
  display:block;
}
.modale{
  height:100%;
  width:100%;
  background-color:#373736;
  position:fixed;
  text-align:center;
  z-index:1000;
}
.position-img{
  position:relative;
}
.flex-center{
  display:flex;
  justify-content:center;
  flex-direction:column;
  align-items:center;

}
.margin-top{
  margin-top:20px;
}

@media screen and (min-width: 400px) {
  .background {
  background: url(../assets/sancosme-movil.png);
   background-color: #DFDFD5;
  height:100%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  transition: all 3s;
  text-align:center;
  }
}

@media screen and (min-width: 300px) {
  .background {
  background: url(../assets/sancosme-movil.png);
   background-color: #DFDFD5;
  height:100%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  transition: all 3s;
  text-align:center;
  }
}

@media screen and (min-width: 800px) {
  .background {
  background: url(../assets/sancosme-movil.png); 
   background-color: #DFDFD5;
  height:100%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  transition: all 3s;
  text-align:center;
  }
}

@media screen and (min-width: 1080px) {
 .background{
  background: url(../assets/sancosme1.png);
  background-color: #DFDFD5;
  height:100%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  transition: all 3s;
  text-align:center;
}
}



.btn-sancosmi{
 background-color:#AB9677;
 padding:10px 20px;
 color:white;
}
.premisaback{
  padding: 10px;
  background-color:#DFDFD5;
}
/*galeria style*/
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px 5px 48px 5px;
}
</style>
